import * as React from 'react';

// import { NextPage } from 'next';
import { NextSeo } from 'next-seo';

import { GetTheApp } from '@/components/common';
import Main from '@/components/shared/Main';
import { SITE_URL } from '@/lib/constants';

const App: React.FC = () => {
  return (
    <Main>
      <NextSeo
        openGraph={{
          images: [
            {
              url: `${SITE_URL}/images/og-default.jpg`,
              width: 1200,
              height: 675,
              alt: 'The Daily Wire',
            },
          ],
        }}
        title='Get the App'
      />
      <GetTheApp />
    </Main>
  );
};

export default App;
