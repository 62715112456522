import * as React from 'react';

import cn from 'classnames';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';

import { ExternalLink } from '@/components/common';

export interface LinkProps extends NextLinkProps, React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
}

export const Link: React.FC<LinkProps> = ({ children, href, className, ...rest }) => {
  const router = useRouter();

  const isActive = router.asPath === href ? `active` : '';
  const isExternal = href?.startsWith('http') ?? false;

  if (isExternal) {
    return (
      <ExternalLink className={cn(className, isActive)} href={href} {...rest}>
        {children}
      </ExternalLink>
    );
  }

  return (
    <NextLink href={href} passHref {...rest}>
      {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={cn(className, isActive)}>{children}</a>
    </NextLink>
  );
};
