/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import { ButtonLink, Icon } from '@/components/design-system';
import { PRAGERU_DONATE_URL } from '@/lib/constants';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

export const PragerDonateLink: React.FC = () => {
  const abTestData = getABTestAnalyticData();
  const utmCookieData = getUtmCookieData();

  return (
    <ButtonLink
      css={{
        paddingLeft: 0,
        fontSize: '14px',
        marginTop: '1rem',
      }}
      href={PRAGERU_DONATE_URL}
      onClick={() =>
        window.__DW_Next_Bridge?.Analytics.logEvent('CTA Clicked', {
          ...abTestData,
          ...utmCookieData,
          type: 'button',
          text: 'Donate to PragerU',
          destination: PRAGERU_DONATE_URL,
          location: 'Episode Banner',
          page_url: window.location.href,
        })
      }
      variant='minimal'
    >
      Donate to PragerU
      <Icon name='CHEVRON_RIGHT' size={16} />
    </ButtonLink>
  );
};
