/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import Image from 'next/image';
import { isAndroid, isIOS } from 'react-device-detect';

import Link from '@/components/shared/Link';
import { Text } from '@/components/ui';
import { MOBILE_APP_APPLE_APP_STORE_URL, MOBILE_APP_GOOGLE_PLAY_STORE_URL } from '@/lib/constants';
import { visuallyHidden } from '@/utils/styles';

import s from './GetTheApp.module.css';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

export const GetTheApp: React.FC = () => {
  const isUnknown = !isIOS && !isAndroid;
  const showAndroid = isUnknown || isAndroid;
  const showIOS = isUnknown || isIOS;

  return (
    <div className={s.root}>
      <div className={s.imgWrap}>
        <Image
          height={375}
          layout='responsive'
          src='https://dailywireplus-v2.imgix.net/images/dailywire.com/dw_app_background.png'
          width={375}
        />
      </div>
      <div className={s.content}>
        <Text spacing='none' variant='pageHeading'>
          Get the App
        </Text>
        <Text css={{ marginTop: 4, color: '#8F8F8F' }} spacing='none' variant='body'>
          We designed this feature specifically for our mobile app to give you the fastest, easiest, and best experience
          possible.
        </Text>
        <div className={s.buttonGroup}>
          <Link
            href={MOBILE_APP_GOOGLE_PLAY_STORE_URL}
            key='google-app-link'
            onClick={() => {
              const abTestData = getABTestAnalyticData();
              const utmCookieData = getUtmCookieData();

              window.__DW_Next_Bridge?.Analytics.logEvent('CTA Clicked', {
                ...abTestData,
                ...utmCookieData,
                type: 'button',
                text: 'Get it on Google Play',
                destination: MOBILE_APP_GOOGLE_PLAY_STORE_URL,
                location: 'Get The App Drawer',
                page_url: window.location.href,
              });
            }}
            style={{ display: showAndroid ? 'block' : 'none' }}
          >
            <Image
              data-testid='get-the-app-google-play'
              height={56}
              layout='responsive'
              src='/images/download-on-google-play.png'
              width={190}
            />
            <span css={visuallyHidden}>Get it on Google Play</span>
          </Link>
          <Link
            href={MOBILE_APP_APPLE_APP_STORE_URL}
            key='apple-app-link'
            onClick={() => {
              const abTestData = getABTestAnalyticData();
              const utmCookieData = getUtmCookieData();

              window.__DW_Next_Bridge?.Analytics.logEvent('CTA Clicked', {
                ...abTestData,
                ...utmCookieData,
                type: 'button',
                text: 'Download on the App Store',
                destination: MOBILE_APP_APPLE_APP_STORE_URL,
                location: 'Get The App Drawer',
                page_url: window.location.href,
              });
            }}
            style={{ display: showIOS ? 'block' : 'none' }}
          >
            <Image
              data-testid='get-the-app-app-store'
              height={56}
              layout='responsive'
              src='/images/download-on-app-store.png'
              width={190}
            />
            <span css={visuallyHidden}>Download on the App Store</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
