import React from 'react';

import { NextSeo } from 'next-seo';

import { APP_STORE_ID, SITE_DESCRIPTION, SITE_URL } from '@/lib/constants';
import { stripHtml, truncate } from '@/utils/helpers';

interface SeoProps {
  description?: string | null;
  image?: string | null;
  imageWidth?: number;
  imageHeight?: number;
  includeMobileAppMeta?: boolean;
  title: string;
  url: string;
}

export const Seo: React.FC<SeoProps> = ({
  title,
  url,
  image = `${SITE_URL}/images/og-default.jpg`,
  imageWidth = 800,
  imageHeight = 600,
  includeMobileAppMeta = false,
  description: descriptionProp,
}) => {
  const description = truncate(stripHtml(descriptionProp ?? SITE_DESCRIPTION), 155);

  const mobileAppMetaTags = [
    { name: 'apple-itunes-app', content: `app-id=${APP_STORE_ID}, app-argument=${url}` },
    { property: 'al:ios:url', content: url },
    { property: 'al:android:url', content: url },
    { name: 'twitter:app:url:iphone', content: url },
    { name: 'twitter:app:url:ipad', content: url },
    { name: 'twitter:app:url:googleplay', content: url },
  ];
  const additionalMetaTags = includeMobileAppMeta ? mobileAppMetaTags : [];

  return (
    <NextSeo
      additionalMetaTags={additionalMetaTags}
      description={description}
      openGraph={{
        url,
        title,
        description,
        images: [
          {
            url: image,
            width: imageWidth,
            height: imageHeight,
          },
        ],
      }}
      title={title}
    />
  );
};
